<template>
<div class="declaration-entrance">
  <div class="left-info">
    <div class="content-div" style="overflow:hidden">
      <!-- <iframe src="https://www.c3mep.cn/login?subPlatformId=1" width="100%" height="100%" frameborder="0"></iframe> -->
      <!-- <div class="title-name">{{titleName}}</div>
      <div class="content-content">
        <p></p>
      </div> -->
      <a href="https://www.c3mep.cn/login?subPlatformId=1" target="_blank"><img src="../assets/imgs/c3mep.png" style="width:100%"/></a>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'PolicyBackground',
  data(){
    return {
      titleName:'智能工厂申报材料填写说明',
      content:'（一）申请条件 注册并纳税在本区的，建设有智能工厂（车间、产线）的市场主体。 （二）申报材料 1、《闵行区智能工厂（车间、产线）申请表》（附件1）一式三份。 2、《闵行区智能工厂（车间、产线）申请报告》（附件2）。 3、上年度企业财务报表及由B级以上会计师事务所出具财务审计报告复印件（会计师事务所B级以上资质证明必须提供）。 4、企业营业执照、上年度纳税证明、法人身份证、联系人身份证、相关的知识产权证明,企业租房合同或产权证明材料等复印件。 5、智能工厂（车间、产线）情况报告，报告内容包括企业介绍、智能工厂介绍、拟建设项目情况等，并附相关高清图片。 6、根据《上海市智能工厂评定流程介绍》（附件3），完成智能工厂线上测评，提供测评后的“测评雷达图”。 （三）申报方法'
    }
  },
  props: {
    msg: String
  },
  created(){
    this.getData(123)
  },
  methods:{
    getData(data){
    },
    toHref(){
      window.open('http://shif.simia.org.cn:8088/','_blank')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.declaration-entrance{
  width: 1400px;
  height: 800px;
  margin-top: 132px;
  @flex_row();
  .left-info{
    width: 1400px;
    height: 796px;
    background: #000066;
    padding: 10px;
  
    .content-div{
      width: 1400px;
      height: 776px;
      margin: 0 10px;
      // overflow: scroll;
      // .title-name{
      //   margin-top: 20px;
      //   margin-bottom: 30px;
      //   line-height: 40px;
      //   font-size: 28px;
      //   font-family: PingFangSC-Medium, PingFang SC;
      //   font-weight: 500;
      //   color: #FFFFFF;
      //   padding: 0 30px;
      //   // @text_ellipsis_one();
      // }
      // .content-content{
      //   padding: 0 20px;
      //   font-size: 24px;
      //   font-family: PingFangHK-Regular, PingFangHK;
      //   font-weight: 400;
      //   color: #CAD3F5;
      //   text-align: justify;
      // }
      // @thumb_scroll()
    }
  
  }
}
// .content-div::-webkit-scrollbar{
//   width: 12px;
//   height: 0px;
// }
// .content-div::-webkit-scrollbar-thumb  
// {  
//   border-radius: 10px;  
//   -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);  
//   background-color: rgba(255, 255, 255, 0.3);  
// } 
</style>
